import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    sidebarShow : true,
    sidebarUnfoldable : false
  },
  reducers: {
    toggleSidebar : (state,action)  => {
      console.log(action)
        return({
          ...state,
          sidebarShow : action.payload
        })
    },
    toggleFold : (state,action)  => {
        return({
          ...state,
          sidebarUnfoldable : action.payload
        })
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggleSidebar, toggleFold } = sidebarSlice.actions

export default sidebarSlice.reducer