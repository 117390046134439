import { configureStore } from '@reduxjs/toolkit'
import userReducer from './Slices/user'
import sidebarReducer from './Slices/sidebar'


export default configureStore({
  reducer: {
    sidebar : sidebarReducer,
    user : userReducer
  }
})
