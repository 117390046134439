import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  contact_number: null,
  address: null,
  type: null,
  is_admin: false,
  is_active: false,
  is_staff: false,
  is_superuser: false,
  groups: [],
  user_permissions: 
    {
      dealer_create: false,
      dealer_delete: false,
      dealer_read: false,
      dealer_update: false,
      ecommerce_create: false,
      ecommerce_delete: false,
      ecommerce_read: false,
      ecommerce_update: false,
      id: null,
      orders_create: false,
      orders_delete: false,
      orders_read: false,
      orders_update: false,
      rewards_create: false,
      rewards_delete: false,
      rewards_read: false,
      rewards_update: false,
      role_name: null,
      showroom_create: false,
      showroom_delete: false,
      showroom_read: false,
      showroom_update: false,
      soldvehicle_create: false,
      soldvehicle_delete: false,
      soldvehicle_read: false,
      soldvehicle_update: false,
      vehical_create: false,
      vehical_delete: false,
      vehical_update: false,
      vehicle_read: false,
      warrantyclaim_create: false,
      warrantyclaim_delete: false,
      warrantyclaim_read: false,
      warrantyclaim_update: false
    }
  ,
  token: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: (state, action) => {
      console.log(action.payload)
      return ({ ...state, ...action.payload })
    },
    initializeToken: (state, action) => {
      return ({ ...state, ...action.payload })
    },
    removeUser: (state) => {
      return ({ ...state, ...initialState })
    }
  }
})

// Action creators are generated for each case reducer function
export const { initializeUser, removeUser, initializeToken } = userSlice.actions

export default userSlice.reducer