import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import CIcon from '@coreui/icons-react'
// import { cilX } from '@coreui/icons'

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
    <ToastContainer
    position="top-right"
    hideProgressBar={true}
    closeOnClick={false}
    closeButton={true}
  />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
